module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/fonts/*":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"**/*.woff":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"**/*.woff2":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"]},"allPageHeaders":["Link: </fonts/NHaasGroteskDSPro-55Rg.woff>; rel=preload; as=font","Link: </fonts/NHaasGroteskDSPro-55Rg.woff2>; rel=preload; as=font","Link: </fonts/NHaasGroteskDSPro-56It.woff>; rel=preload; as=font","Link: </fonts/NHaasGroteskDSPro-56It.woff2>; rel=preload; as=font","Link: </fonts/NHaasGroteskDSPro-65Md.woff>; rel=preload; as=font","Link: </fonts/NHaasGroteskDSPro-65Md.woff2>; rel=preload; as=font","Link: </fonts/NHaasGroteskDSPro-66MdIt.woff>; rel=preload; as=font","Link: </fonts/NHaasGroteskDSPro-66MdIt.woff2>; rel=preload; as=font"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"427199965586716"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
